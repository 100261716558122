import axios from "axios";
import decodeJwt from "jwt-decode";
//const host = "https://api.bellamare.pl/users/login";
const host = "https://api.bellamare.pl/users/login";

const authProvider = {
  login: ({ username, password }) => {
    return axios
      .post(
        host,
        {
          email: username,
          password,
        },
        {
          headers: {
            "Content-Type": "application/json; charset=UTF-8",
          },
        }
      )
      .then((res) => {
        localStorage.setItem("auth-token", res.data);

        const permissions = res.data.permissions;
        localStorage.setItem("permissions", JSON.stringify(permissions));
      })
      .catch((error) => {
        console.log(error.response);
      });
  },
  checkError: (error) => {
    const status = error.status;

    if (status === 401 || status === 403) {
      localStorage.removeItem("auth-token");
      return Promise.resolve();
    }

    return Promise.reject();
  },
  checkAuth: () =>
    localStorage.getItem("auth-token") ? Promise.resolve() : Promise.reject(),
  logout: () => {
    localStorage.removeItem("auth-token");
    return Promise.resolve();
  },
  getIdentity: () => {
    try {
      const { id, name, lastname, email } = decodeJwt(
        localStorage.getItem("auth-token")
      );
      return Promise.resolve({ id, name, lastname, email });
    } catch (error) {
      return Promise.reject(error);
    }
  },
  getPermissions: () => {
    const role = localStorage.getItem("permissions");
    return role ? Promise.resolve(role) : Promise.reject();
  },
};

export default authProvider;
