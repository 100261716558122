import {
  Datagrid,
  DateInput,
  DeleteWithConfirmButton,
  Edit,
  EditButton,
  FormTab,
  List,
  SelectInput,
  TabbedForm,
  TextField,
  TextInput,
  Create,
  ImageInput,
  ImageField,
  SimpleShowLayout,
  DateField,
  required,
  BooleanInput,
  BooleanField,
  ChipField,
} from "react-admin";
import {
  AlignmentButtons,
  ClearButtons,
  ColorButtons,
  FormatButtons,
  LevelSelect,
  LinkButtons,
  RichTextInput,
  RichTextInputToolbar,
} from "ra-input-rich-text";
import SimpleArray from "../../utils/getImages";
import { Grid } from "@material-ui/core";
import Pedigree from "../../utils/pedigree";

export const DogsList = (props) => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="id" label="ID" />
      <ChipField source="isVisible" label="Widoczność" />
      <TextField source="name" label="Imię" />
      <TextField source="gender" label="Płeć" />
      <TextField source="category" label="Kategoria" />
      <DateField source="birthDate" label="Data urodzenia" />
      <EditButton label="Edytuj" />
      <DeleteWithConfirmButton
        label="Usuń"
        confirmContent="Czy na pewno chcesz usunąć psa z bazy danych?"
        color="warning"
      />
    </Datagrid>
  </List>
);

export const DogsEdit = (props) => (
  <Edit {...props} title="Edytuj psa">
    <TabbedForm>
      <FormTab label="Dane psa">
        <Grid container spacing="2">
          <Grid item xs="3" fullWidth>
            <TextInput source="name" label="Imię" fullWidth />
          </Grid>
          <Grid item xs="3">
            <DateInput source="birthDate" label="Data urodzenia" fullWidth />
          </Grid>
          <Grid item xs="3">
            <BooleanInput source="isVisible" label="Widoczność" fullWidth />
          </Grid>
        </Grid>

        <Grid container fullWidth spacing={2}>
          <Grid item xs="3">
            <SelectInput
              source="gender"
              label="Płeć"
              choices={[
                { id: "male", name: "Pies" },
                { id: "female", name: "Suka" },
              ]}
              fullWidth
            />
          </Grid>
          <Grid item xs="3">
            <SelectInput
              source="category"
              label="Kategoria"
              choices={[
                { id: "males", name: "Pies" },
                { id: "females", name: "Suka" },
                { id: "youngsters", name: "Młodzież" },
                { id: "memory", name: "Były w hodowli" },
                { id: "semen", name: "Nasienie" },
                { id: "bredyouth", name: "Wyhodowana młodzież" },
              ]}
              fullWidth
            />
          </Grid>
        </Grid>
      </FormTab>
      <FormTab label="Rodowód">
        <Grid container>
          <Grid item xs="12">
            <Pedigree source="pedigree" fullWidth />
          </Grid>
        </Grid>
      </FormTab>
      <FormTab label="Język Polski">
        <RichTextInput
          source="description_pl"
          label="Opis psa po Polsku"
          toolbar={
            <RichTextInputToolbar>
              <LevelSelect />
              <FormatButtons />
              <ColorButtons />
              <AlignmentButtons />
              <LinkButtons />
              <ClearButtons />
            </RichTextInputToolbar>
          }
          fullWidth
        />
      </FormTab>
      <FormTab label="Język Angielski">
        <RichTextInput
          source="description_en"
          fullWidth
          label="Opis psa po Angielsku"
          toolbar={
            <RichTextInputToolbar>
              <LevelSelect />
              <FormatButtons />
              <ColorButtons />
              <AlignmentButtons />
              <LinkButtons />
              <ClearButtons />
            </RichTextInputToolbar>
          }
        />
      </FormTab>
      <FormTab label="Zdjęcia">
        <Grid container spacing={2}>
          <Grid item xs="12">
            <ImageInput
              source="mainImg"
              label="Zdjęcie główne"
              accept="image/png,image/jpg,image/jpeg"
            >
              <ImageField source="src" title="title" />
            </ImageInput>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs="12">
            <ImageInput
              source="gallery"
              label="Gallery"
              accept="image/png,image/jpg,image/jpeg"
              multiple
            >
              <ImageField source="src" title="title" />
            </ImageInput>
          </Grid>
        </Grid>
        <Grid container spacing={2} fullWidth>
          <Grid item xs="12">
            <SimpleShowLayout fullWidth>
              <SimpleArray source="images" />
            </SimpleShowLayout>
          </Grid>
        </Grid>
      </FormTab>
    </TabbedForm>
  </Edit>
);

export const DogsCreate = (props) => (
  <Create {...props} title="Dodaj nowego psa">
    <TabbedForm>
      <FormTab label="Dane psa">
        <Grid container spacing="2">
          <Grid item xs="3" fullWidth>
            <TextInput
              source="name"
              label="Imię"
              fullWidth
              validate={required()}
            />
          </Grid>
          <Grid item xs="3">
            <DateInput source="birthDate" label="Data urodzenia" fullWidth />
          </Grid>
          <Grid item xs="3">
            <BooleanInput source="isVisible" label="Widoczność" fullWidth />
          </Grid>
        </Grid>

        <Grid container fullWidth spacing={2}>
          <Grid item xs="3">
            <SelectInput
              source="gender"
              label="Płeć"
              validate={required()}
              choices={[
                { id: "male", name: "Pies" },
                { id: "female", name: "Suka" },
              ]}
              fullWidth
            />
          </Grid>
          <Grid item xs="3">
            <SelectInput
              source="category"
              label="Kategoria"
              validate={required()}
              choices={[
                { id: "males", name: "Pies" },
                { id: "females", name: "Suka" },
                { id: "youngsters", name: "Młodzież" },
                { id: "memory", name: "Były w hodowli" },
                { id: "semen", name: "Nasienie" },
                { id: "bredyouth", name: "Wyhodowana młodzież" },
              ]}
              fullWidth
            />
          </Grid>
        </Grid>
      </FormTab>
      <FormTab label="Rodowód">
        <Pedigree source="pedigree" />
      </FormTab>
      <FormTab label="Język Polski">
        <RichTextInput
          source="description_pl"
          label="Opis psa po Polsku"
          toolbar={
            <RichTextInputToolbar>
              <LevelSelect />
              <FormatButtons />
              <ColorButtons />
              <AlignmentButtons />
              <LinkButtons />
              <ClearButtons />
            </RichTextInputToolbar>
          }
          fullWidth
        />
      </FormTab>
      <FormTab label="Język Angielski">
        <RichTextInput
          source="description_en"
          fullWidth
          label="Opis psa po Angielsku"
          toolbar={
            <RichTextInputToolbar>
              <LevelSelect />
              <FormatButtons />
              <ColorButtons />
              <AlignmentButtons />
              <LinkButtons />
              <ClearButtons />
            </RichTextInputToolbar>
          }
        />
      </FormTab>
      <FormTab label="Zdjęcia">
        <Grid container spacing={2}>
          <Grid item xs="12">
            <ImageInput
              source="mainImg"
              label="Zdjęcie główne"
              accept="image/png,image/jpg,image/jpeg"
              validate={required()}
            >
              <ImageField source="src" title="title" />
            </ImageInput>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs="12">
            <ImageInput
              source="gallery"
              label="Gallery"
              accept="image/png,image/jpg,image/jpeg"
              multiple
            >
              <ImageField source="src" title="title" />
            </ImageInput>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs="12">
            <SimpleShowLayout fullWidth>
              <SimpleArray source="images" />
            </SimpleShowLayout>
          </Grid>
        </Grid>
      </FormTab>
    </TabbedForm>
  </Create>
);
