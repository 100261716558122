import {
  List,
  Datagrid,
  TextField,
  EditButton,
  DeleteButton,
  TabbedForm,
  FormTab,
  Create,
  ImageInput,
  ImageField,
  required,
  UrlField,
  FunctionField,
  NumberField,
} from "react-admin";

export const ImageList = (props) => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="id" label="ID" />
      <FunctionField
        label="Image"
        render={(record) => {
          return <img src={`https://api.bellamare.pl/${record.pathThumb}`} />;
        }}
      />
      {/* <ImageField source="path" label="Zdjęcię" /> */}
      <FunctionField
        label="path"
        render={(record) => {
          return (
            <a
              href={`https://api.bellamare.pl/${record.path}`}
              target="__blank"
            >
              {`https://api.bellamare.pl/${record.path}`}
            </a>
          );
        }}
      />
      <NumberField source="subID" label="ID" />
      <TextField source="subType" label="Type" />
      <DeleteButton label="Usuń" />
    </Datagrid>
  </List>
);

export const ImageCreate = (props) => (
  <Create title="Dodaj nowe zdjęcię" {...props}>
    <TabbedForm>
      <FormTab label="Zdjęcie">
        <ImageInput
          source="image"
          label="Gallery"
          accept="image/png,image/jpg,image/jpeg"
          validate={required()}
        >
          <ImageField source="src" title="title" />
        </ImageInput>
      </FormTab>
    </TabbedForm>
  </Create>
);
