import { fetchUtils } from "react-admin";
import restServerProvider from "ra-data-json-server";

const servicesHost = "https://api.bellamare.pl";
//const servicesHost = "https://api.bellamare.pl";

const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }
  const token = localStorage.getItem("auth-token");
  // add your own headers here
  options.headers.set("auth-token", token);

  return fetchUtils.fetchJson(url, options);
};

const dataRestProvider = restServerProvider(servicesHost, httpClient);

let formData;

function getElements(resource, params) {
  formData = new FormData();
  switch (resource) {
    case "news": {
      formData.append("title_pl", params.data.title_pl);
      formData.append("title_en", params.data.title_en);
      formData.append("description_pl", params.data.description_pl);
      formData.append("description_en", params.data.description_en);
      formData.append("addedAt", params.data.addedAt);

      //images
      if (params.data.gallery) {
        params.data.gallery.forEach((item) => {
          formData.append("gallery", item.rawFile);
        });
      }
      break;
    }
    case "dogs": {
      formData.append("name", params.data.name);
      formData.append("gender", params.data.gender);
      formData.append("category", params.data.category);
      formData.append("birthDate", params.data.birthDate);
      formData.append("description_pl", params.data.description_pl);
      formData.append("description_en", params.data.description_en);
      formData.append("pedigree", JSON.stringify(params.data.pedigree));
      formData.append("isVisible", params.data.isVisible);

      //images
      params.data.mainImg &&
        formData.append("mainImg", params.data.mainImg.rawFile);

      params.data.gallery &&
        params.data.gallery.forEach((item) => {
          formData.append("gallery", item.rawFile);
        });

      break;
    }
    case "litters": {
      formData.append("name_pl", params.data.name_pl);
      formData.append("name_en", params.data.name_en);
      formData.append("birthDate", params.data.birthDate);
      formData.append("description_pl", params.data.description_pl);
      formData.append("description_en", params.data.description_en);
      formData.append("type", params.data.type);

      //images
      if (params.data.gallery) {
        params.data.gallery.forEach((item) => {
          formData.append("gallery", item.rawFile);
        });
      }
      break;
    }
    case "aboutus": {
      formData.append("title_pl", params.data.title_pl);
      formData.append("title_en", params.data.title_en);
      formData.append("description_pl", params.data.description_pl);
      formData.append("description_en", params.data.description_en);

      if (params.data.gallery) {
        params.data.gallery.forEach((item) => {
          formData.append("gallery", item.rawFile);
        });
      }

      break;
    }
    case "aboutbreed": {
      formData.append("description_pl", params.data.description_pl);
      formData.append("description_en", params.data.description_en);
      formData.append("title_pl", params.data.title_pl);
      formData.append("title_en", params.data.title_en);

      if (params.data.gallery) {
        params.data.gallery.forEach((item) => {
          formData.append("gallery", item.rawFile);
        });
      }
      break;
    }

    case "puppies": {
      formData.append("description_pl", params.data.description_pl);
      formData.append("description_en", params.data.description_en);
      formData.append("title_pl", params.data.title_pl);
      formData.append("title_en", params.data.title_en);

      if (params.data.gallery) {
        params.data.gallery.forEach((item) => {
          formData.append("gallery", item.rawFile);
        });
      }
      break;
    }

    case "files": {
      if (params.data.image) {
        formData.append("image", params.data.image.rawFile);
      }
      break;
    }

    default: {
      break;
    }
  }
}

const myDataProvider = {
  ...dataRestProvider,
  create: (resource, params) => {
    getElements(resource, params);

    return httpClient(`${servicesHost}/${resource}`, {
      method: "POST",
      body: formData,
    }).then(({ json }) => ({
      data: { ...params.data, id: json.id },
    }));
  },
  update: (resource, params) => {
    getElements(resource, params);

    return httpClient(`${servicesHost}/${resource}/${params.id}`, {
      method: "PUT",
      body: formData,
    }).then(() => ({
      data: { ...params.data },
    }));
  },
};

export default myDataProvider;
