import { TextInput, useRecordContext } from "react-admin";
import "./pedigree.css";

export default function Pedigree({ source }) {
  const record = useRecordContext();

  return (
    <table className="tg">
      <thead>
        <tr>
          {/* Mother */}
          <th className="tg-xwyw" rowSpan="4">
            <TextInput source="pedigree.father.name" label="Ojciec" fullWidth />
          </th>

          {/* GrandFather */}
          <th className="tg-xwyw" rowSpan="2">
            <TextInput
              source="pedigree.father.grandfather.name"
              label="Ojciec"
              fullWidth
            />
          </th>

          {/* GreatGrandFather */}
          <th className="tg-xwyw">
            <TextInput
              source="pedigree.father.grandmother.greatgrandfather.name"
              label="Ojciec"
              fullWidth
            />
          </th>
        </tr>
        <tr>
          {/* GreatGrandMother */}
          <th className="tg-xwyw">
            {" "}
            <TextInput
              source="pedigree.father.grandmother.greatgrandmother.name"
              label="Matka"
              fullWidth
            />{" "}
          </th>
        </tr>
        <tr>
          {/* GrandMother */}
          <th className="tg-xwyw" rowSpan="2">
            <TextInput
              source="pedigree.father.grandmother.name"
              label="Matka"
              fullWidth
            />
          </th>
          {/* GreatGrandFather */}
          <th className="tg-xwyw">
            <TextInput
              source="pedigree.father.grandfather.greatgrandfather.name"
              label="Ojciec"
              fullWidth
            />
          </th>
        </tr>
        <tr>
          {/* GreatGrandMother */}
          <th className="tg-xwyw">
            <TextInput
              source="pedigree.father.grandfather.greatgrandmother.name"
              label="Matka"
              fullWidth
            />
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          {/* Mother */}
          <th className="tg-xwyw" rowSpan="4">
            <TextInput source="pedigree.mother.name" label="Matka" fullWidth />
          </th>

          {/* GrandFather */}
          <th className="tg-xwyw" rowSpan="2">
            <TextInput
              source="pedigree.mother.grandfather.name"
              label="Ojciec"
              fullWidth
            />
          </th>

          {/* GreatGrandFather */}
          <th className="tg-xwyw">
            <TextInput
              source="pedigree.mother.grandmother.greatgrandfather.name"
              label="Ojciec"
              fullWidth
            />
          </th>
        </tr>
        <tr>
          {/* GreatGrandMother */}
          <th className="tg-xwyw">
            {" "}
            <TextInput
              source="pedigree.mother.grandmother.greatgrandmother.name"
              label="Matka"
              fullWidth
            />{" "}
          </th>
        </tr>
        <tr>
          {/* GrandMother */}
          <th className="tg-xwyw" rowSpan="2">
            <TextInput
              source="pedigree.mother.grandmother.name"
              label="Matka"
              fullWidth
            />
          </th>
          {/* GreatGrandFather */}
          <th className="tg-xwyw">
            <TextInput
              source="pedigree.mother.grandfather.greatgrandfather.name"
              label="Ojciec"
              fullWidth
            />
          </th>
        </tr>
        <tr>
          {/* GreatGrandMother */}
          <th className="tg-xwyw">
            <TextInput
              source="pedigree.mother.grandfather.greatgrandmother.name"
              label="Matka"
              fullWidth
            />
          </th>
        </tr>
      </tbody>
    </table>
  );
}
