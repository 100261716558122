import { Grid } from "@material-ui/core";
import {
  AlignmentButtons,
  ClearButtons,
  ColorButtons,
  FormatButtons,
  LevelSelect,
  LinkButtons,
  RichTextInput,
  RichTextInputToolbar,
} from "ra-input-rich-text";
import {
  Datagrid,
  DateInput,
  DeleteButton,
  Edit,
  EditButton,
  FormTab,
  List,
  TabbedForm,
  TextField,
  TextInput,
  ImageField,
  ImageInput,
  Create,
  required,
  DateField,
  DeleteWithConfirmButton,
  SimpleShowLayout,
  SelectInput,
} from "react-admin";
import ImageDeleteField from "../../utils/getImages";
import SimpleArray from "../../utils/getImages";

export const LittersList = (props) => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="id" label="ID" />
      <TextField source="name_pl" label="Nazwa miotu" />
      <TextField source="type" label="Rodzaj miotu" />
      <DateField source="birthDate" label="Data urodzenia" />
      <EditButton label="Edytuj" />
      <DeleteWithConfirmButton
        label="Usuń"
        confirmContent="Czy na pewno chcesz usunąć miot?"
        confirmTitle="Usuwanie miotu!"
        color="error"
      />
    </Datagrid>
  </List>
);

export const LittersEdit = (props) => (
  <Edit {...props} title="Edytuj miot">
    <TabbedForm>
      <FormTab label="Informacje o miocie">
        <Grid container>
          <Grid item xs="3">
            <DateInput
              fullWidth
              source="birthDate"
              label="Data urodzenia miotu:"
              validate={required()}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs="3">
            <SelectInput
              source="type"
              label="Rodzaj miotu"
              validate={required()}
              choices={[
                { id: "current", name: "Aktualny" },
                { id: "planned", name: "Planowany" },
                { id: "archive", name: "Archiwum" },
              ]}
              fullWidth
            />
          </Grid>
        </Grid>
      </FormTab>
      <FormTab label="Język Polski">
        <Grid container>
          <Grid item xs="6">
            <TextInput
              source="name_pl"
              label="Nazwa miotu po Polsku:"
              validate={required()}
              fullWidth
            />
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs="12">
            <RichTextInput
              fullWidth
              source="description_pl"
              label="Opis miotu po Polsku"
              toolbar={
                <RichTextInputToolbar>
                  <LevelSelect />
                  <FormatButtons />
                  <ColorButtons />
                  <AlignmentButtons />
                  <LinkButtons />
                  <ClearButtons />
                </RichTextInputToolbar>
              }
            />
          </Grid>
        </Grid>
      </FormTab>
      <FormTab label="Język Angielski">
        <Grid container>
          <Grid item xs="6">
            <TextInput
              source="name_en"
              label="Nazwa miotu po Angielsku:"
              validate={required()}
              fullWidth
            />
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs="12">
            <RichTextInput
              fullWidth
              source="description_en"
              label="Opis miotu po Angielsku"
              toolbar={
                <RichTextInputToolbar>
                  <LevelSelect />
                  <FormatButtons />
                  <ColorButtons />
                  <AlignmentButtons />
                  <LinkButtons />
                  <ClearButtons />
                </RichTextInputToolbar>
              }
            />
          </Grid>
        </Grid>
      </FormTab>
      <FormTab label="Zdjęcia">
        <Grid container fullWidth>
          <Grid item xs="12">
            <ImageInput
              source="gallery"
              label="Galeria"
              accept="image/png,image/jpg,image/jpeg"
              fullWidth
              multiple
            >
              <ImageField source="src" title="title" />
            </ImageInput>
          </Grid>
        </Grid>
        <Grid container spacing={2} fullWidth>
          <Grid item xs="12">
            <SimpleShowLayout fullWidth>
              <SimpleArray source="images" />
            </SimpleShowLayout>
          </Grid>
        </Grid>
      </FormTab>
    </TabbedForm>
  </Edit>
);

export const LittersCreate = (props) => (
  <Create {...props} title="Stwórz nowy miot">
    <TabbedForm>
      <FormTab label="Informacje o miocie">
        <Grid container>
          <Grid item xs="3">
            <DateInput
              fullWidth
              source="birthDate"
              label="Data urodzenia miotu:"
              validate={required()}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs="3">
            <SelectInput
              source="type"
              label="Rodzaj miotu"
              validate={required()}
              choices={[
                { id: "current", name: "Aktualny" },
                { id: "planned", name: "Planowany" },
                { id: "archive", name: "Archiwum" },
              ]}
              fullWidth
            />
          </Grid>
        </Grid>
      </FormTab>
      <FormTab label="Język Polski">
        <Grid container>
          <Grid item xs="6">
            <TextInput
              source="name_pl"
              label="Nazwa miotu po Polsku:"
              validate={required()}
              fullWidth
            />
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs="12">
            <RichTextInput
              fullWidth
              source="description_pl"
              label="Opis miotu po Polsku"
              toolbar={
                <RichTextInputToolbar>
                  <LevelSelect />
                  <FormatButtons />
                  <ColorButtons />
                  <AlignmentButtons />
                  <LinkButtons />
                  <ClearButtons />
                </RichTextInputToolbar>
              }
            />
          </Grid>
        </Grid>
      </FormTab>
      <FormTab label="Język Angielski">
        <Grid container>
          <Grid item xs="6">
            <TextInput
              source="name_en"
              label="Nazwa miotu po Angielsku:"
              validate={required()}
              fullWidth
            />
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs="12">
            <RichTextInput
              fullWidth
              source="description_en"
              label="Opis miotu po Angielsku"
              toolbar={
                <RichTextInputToolbar>
                  <LevelSelect />
                  <FormatButtons />
                  <ColorButtons />
                  <AlignmentButtons />
                  <LinkButtons />
                  <ClearButtons />
                </RichTextInputToolbar>
              }
            />
          </Grid>
        </Grid>
      </FormTab>
      <FormTab label="Zdjęcia">
        <Grid container>
          <Grid item xs="12">
            <ImageInput
              source="gallery"
              label="Galeria"
              accept="image/png,image/jpg,image/jpeg"
              fullWidth
              multiple
            >
              <ImageField source="src" title="title" />
            </ImageInput>
          </Grid>
        </Grid>
      </FormTab>
    </TabbedForm>
  </Create>
);
