import axios from "axios";
import "./style.css";
import { useRecordContext } from "react-admin";
import setPathtoImage from "./setPathtoImage";

async function RemoveImg(id) {
  let message = "";
  if (window.confirm("Czy na pewno chcesz usunąć zdjęcie o ID: " + id + "?")) {
    try {
      await axios
        .delete(`https://api.bellamare.pl/files/${id}`, {
          headers: {
            "auth-token": localStorage.getItem("auth-token"),
          },
        })
        .then((res) => {
          res.data.message === "success" ? location.reload() : "";
        });
    } catch (error) {
      message = error.message;
      return alert(message);
    }
  } else {
    console.log("Error: błąd podczas usuwnaia zdjęcia!");
  }
}

async function updateSortID(sortID, id) {
  await axios
    .put(
      `https://api.bellamare.pl/files`,
      { id: id, sortID: sortID },
      {
        headers: {
          "auth-token": localStorage.getItem("auth-token"),
        },
      }
    )
    .then(() => {
      location.reload();
    })
    .catch((error) => console.log(error));
}

async function updateTitleImage(text, id) {
  text === "" ? " " : text;
  await axios
    .put(
      `https://api.bellamare.pl/files`,
      { id: id, title: text },
      {
        headers: {
          "auth-token": localStorage.getItem("auth-token"),
        },
      }
    )
    .then(() => {
      location.reload();
    })
    .catch((error) => console.log(error));
}

function ImagesList(gallery) {
  if (!gallery) return;

  return (
    <>
      {gallery
        .filter((f) => f.field === "mainImg")
        .map((im) => (
          <div className="image border" key={im.id}>
            <img
              src={setPathtoImage(im.pathThumb)}
              alt=""
              key={im.id}
              className="img"
            />
          </div>
        ))}

      {gallery
        .filter((f) => f.field !== "mainImg")
        .sort(function (a, b) {
          return a.sortID - b.sortID;
        })
        .map((im) => (
          <div className="container" key={im.id}>
            <div className="image">
              <div className="imageDelete" onClick={() => RemoveImg(im.id)}>
                X
              </div>
              <img src={setPathtoImage(im.pathThumb)} alt="" className="img" />
            </div>

            <div>
              <input
                defaultValue={im.sortID}
                className="sortID"
                type="number"
                label="SortID"
                onBlur={(e) => updateSortID(e.target.value, im.id)}
                placeholder="ID"
              />

              <input
                defaultValue={im.title}
                className="title"
                type="text"
                label="Title"
                onBlur={(e) => updateTitleImage(e.target.value, im.id)}
                placeholder="Wpisz tekst"
              />
            </div>
          </div>
        ))}
    </>
  );

  // return gallery.map((im) => (
  //   <div
  //     className={`image ${im.field === "mainImg" ? "border" : ""}`}
  //     key={im.id}
  //   >
  //     {im.field === "mainImg" ? (
  //       ""
  //     ) : (
  //       <div
  //         className="imageDelete"
  //         onClick={() => {
  //           RemoveImg(im.id);
  //         }}
  //       >
  //         X
  //       </div>
  //     )}
  //     <img src={im.path} alt="" key={im.id} className="img" />
  //   </div>
  // ));
}

const SimpleArray = ({ source }) => {
  const record = useRecordContext();
  return <div className="imagesContainer">{ImagesList(record[source])}</div>;
};

export default SimpleArray;
