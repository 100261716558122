import {
  List,
  Datagrid,
  TextField,
  EditButton,
  DeleteButton,
  Edit,
  TabbedForm,
  FormTab,
  TextInput,
  Create,
  required,
  ImageInput,
  ImageField,
  DateField,
  SimpleShowLayout,
  DateInput,
  DeleteWithConfirmButton,
} from "react-admin";
import SimpleArray from "../../utils/getImages";
import {
  AlignmentButtons,
  ClearButtons,
  ColorButtons,
  FormatButtons,
  LevelSelect,
  LinkButtons,
  RichTextInput,
  RichTextInputToolbar,
} from "ra-input-rich-text";
import { Grid } from "@material-ui/core";

export const NewsList = (props) => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="id" label="ID" />
      <TextField source="title_pl" label="Tytuł PL" />
      <TextField source="title_en" label="Tytuł EN" />
      <DateField source="addedAt" label="Utworzony" />
      <DateField source="editedAt" label="Edytowany" />
      <EditButton label="Edytuj" />
      <DeleteWithConfirmButton
        label="Usuń"
        confirmContent="Czy na pewno chcesz usunąć newsa?"
        confirmTitle="Usuwanie newsa!"
        color="warning"
      />
    </Datagrid>
  </List>
);

export const NewsEdit = (props) => (
  <Edit title="Edytuj news'a" {...props}>
    <TabbedForm>
      <FormTab label="Ustawienia">
        <Grid container spacing={6}>
          <Grid item xs="4">
            <DateInput source="addedAt" />
          </Grid>
        </Grid>
      </FormTab>
      <FormTab label="Język Polski">
        <Grid container spacing={6}>
          <Grid item xs="6">
            <TextInput source="title_pl" label="Tytuł po Polsku" />
          </Grid>
        </Grid>
        <Grid container spacing={6}>
          <Grid item xs="12">
            <RichTextInput
              fullWidth
              source="description_pl"
              label="Opis news po Polsku"
              validate={required()}
              toolbar={
                <RichTextInputToolbar>
                  <LevelSelect />
                  <FormatButtons />
                  <ColorButtons />
                  <AlignmentButtons />
                  <LinkButtons />
                  <ClearButtons />
                </RichTextInputToolbar>
              }
            />
          </Grid>
        </Grid>
      </FormTab>
      <FormTab label="Język Angielski">
        <Grid container spacing={2}>
          <Grid item xs="6">
            <TextInput source="title_en" label="Tytuł po Angielsku" />
          </Grid>
        </Grid>
        <RichTextInput
          fullWidth
          source="description_en"
          label="Opis news po Angielsku"
          validate={required()}
          toolbar={
            <RichTextInputToolbar>
              <LevelSelect />
              <FormatButtons />
              <ColorButtons />
              <AlignmentButtons />
              <LinkButtons />
              <ClearButtons />
            </RichTextInputToolbar>
          }
        />
      </FormTab>
      <FormTab label="Zdjęcia">
        <Grid container spacing={2}>
          <Grid item xs="12">
            <ImageInput
              source="gallery"
              label="Gallery"
              accept="image/png,image/jpg,image/jpeg"
              multiple
            >
              <ImageField source="src" title="title" />
            </ImageInput>
          </Grid>
        </Grid>
        <Grid container spacing={2} fullWidth>
          <Grid item xs="12">
            <SimpleShowLayout fullWidth>
              <SimpleArray source="images" />
            </SimpleShowLayout>
          </Grid>
        </Grid>
      </FormTab>
    </TabbedForm>
  </Edit>
);

export const NewsCreate = (props) => (
  <Create title="Utwórz nowego news'a" {...props}>
    <TabbedForm>
      <FormTab label="Ustawienia">
        <Grid container spacing={6}>
          <Grid item xs="3">
            <DateInput source="addedAt" label="Data dodania" fullWidth />
          </Grid>
        </Grid>
      </FormTab>
      <FormTab label="Język Polski">
        <Grid container>
          <Grid item xs="6">
            <TextInput source="title_pl" label="Tytuł po Polsku" fullWidth />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs="12">
            <RichTextInput
              fullWidth
              source="description_pl"
              label="Treść newsa po Polsku"
              validate={required()}
              toolbar={
                <RichTextInputToolbar>
                  <LevelSelect />
                  <FormatButtons />
                  <ColorButtons />
                  <AlignmentButtons />
                  <LinkButtons />
                  <ClearButtons />
                </RichTextInputToolbar>
              }
            />
          </Grid>
        </Grid>
      </FormTab>
      <FormTab label="Język Angielski">
        <Grid container>
          <Grid item xs="6">
            <TextInput source="title_en" label="Tytuł po Angielsku" fullWidth />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs="12">
            <RichTextInput
              fullWidth
              source="description_en"
              label="Treść newsa po Angielsku"
              validate={required()}
              toolbar={
                <RichTextInputToolbar>
                  <LevelSelect />
                  <FormatButtons />
                  <ColorButtons />
                  <AlignmentButtons />
                  <LinkButtons />
                  <ClearButtons />
                </RichTextInputToolbar>
              }
            />
          </Grid>
        </Grid>
      </FormTab>
      <FormTab label="Zdjęcia">
        <ImageInput
          source="gallery"
          label="Gallery"
          accept="image/png,image/jpg,image/jpeg"
          multiple
        >
          <ImageField source="src" title="title" />
        </ImageInput>
      </FormTab>
    </TabbedForm>
  </Create>
);
