import { Grid } from "@material-ui/core";
import {
  AlignmentButtons,
  ClearButtons,
  ColorButtons,
  FormatButtons,
  LevelSelect,
  LinkButtons,
  RichTextInput,
  RichTextInputToolbar,
} from "ra-input-rich-text";
import {
  Datagrid,
  DateInput,
  DeleteButton,
  Edit,
  EditButton,
  FormTab,
  List,
  TabbedForm,
  TextField,
  TextInput,
  ImageField,
  ImageInput,
  Create,
  required,
  ReferenceInput,
  SelectInput,
  DeleteWithConfirmButton,
} from "react-admin";
import ImageDeleteField from "../../utils/getImages";

export const PuppiesList = (props) => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="id" label="ID" />
      <TextField source="title_pl" label="Nazwa PL" />
      <TextField source="title_en" label="Nazwa EN" />
      <EditButton label="Edytuj" />
      <DeleteWithConfirmButton
        label="Usuń"
        confirmContent="Czy na pewno chcesz usunąć wpis?"
        confirmTitle="Usuń wpis szczeniąt"
        color="error"
      />
    </Datagrid>
  </List>
);

export const PuppyEdit = (props) => (
  <Edit {...props} title="Edytuj szczeniaka">
    <TabbedForm>
      <FormTab label="Język Polski">
        <Grid container>
          <Grid item xs="6">
            <TextInput source="title_pl" label="Tytuł po Polsku" fullWidth />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs="12">
            <RichTextInput
              fullWidth
              source="description_pl"
              label="Wpis szczeniąt po Polsku"
              validate={required()}
              toolbar={
                <RichTextInputToolbar>
                  <LevelSelect />
                  <FormatButtons />
                  <ColorButtons />
                  <AlignmentButtons />
                  <LinkButtons />
                  <ClearButtons />
                </RichTextInputToolbar>
              }
            />
          </Grid>
        </Grid>
      </FormTab>
      <FormTab label="Język Angielski">
        <Grid container>
          <Grid item xs="6">
            <TextInput source="title_en" label="Tytuł po Angielsku" fullWidth />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs="12">
            <RichTextInput
              fullWidth
              source="description_en"
              label="Wpis szczeniąt po Angielsku"
              validate={required()}
              toolbar={
                <RichTextInputToolbar>
                  <LevelSelect />
                  <FormatButtons />
                  <ColorButtons />
                  <AlignmentButtons />
                  <LinkButtons />
                  <ClearButtons />
                </RichTextInputToolbar>
              }
            />
          </Grid>
        </Grid>
      </FormTab>
      <FormTab label="Zdjęcia">
        <Grid container spacing={2}>
          <Grid item xs="12">
            <ImageInput
              source="gallery"
              label="Galeria"
              accept="image/png,image/jpg,image/jpeg"
              multiple
              fullWidth
            >
              <ImageField source="src" title="title" />
            </ImageInput>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs="12">
            <ImageDeleteField source="images" />
          </Grid>
        </Grid>
      </FormTab>
    </TabbedForm>
  </Edit>
);

export const PuppyCreate = (props) => (
  <Create {...props} title="Stwórz nowe szczenię">
    <TabbedForm>
      <FormTab label="Język Polski">
        <Grid container>
          <Grid item xs="6">
            <TextInput source="title_pl" label="Tytuł po Polsku" fullWidth />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs="12">
            <RichTextInput
              fullWidth
              source="description_pl"
              label="Wpis szczeniąt po Polsku"
              validate={required()}
              toolbar={
                <RichTextInputToolbar>
                  <LevelSelect />
                  <FormatButtons />
                  <ColorButtons />
                  <AlignmentButtons />
                  <LinkButtons />
                  <ClearButtons />
                </RichTextInputToolbar>
              }
            />
          </Grid>
        </Grid>
      </FormTab>
      <FormTab label="Język Angielski">
        <Grid container>
          <Grid item xs="6">
            <TextInput source="title_en" label="Tytuł po Angielsku" fullWidth />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs="12">
            <RichTextInput
              fullWidth
              source="description_en"
              label="Wpis szczeniąt po Angielsku"
              validate={required()}
              toolbar={
                <RichTextInputToolbar>
                  <LevelSelect />
                  <FormatButtons />
                  <ColorButtons />
                  <AlignmentButtons />
                  <LinkButtons />
                  <ClearButtons />
                </RichTextInputToolbar>
              }
            />
          </Grid>
        </Grid>
      </FormTab>
      <FormTab label="Zdjęcia">
        <Grid container>
          <Grid item m="10px" xs="12">
            <ImageInput
              source="gallery"
              label="Galeria"
              accept="image/png,image/jpg,image/jpeg"
              fullWidth
              multiple
            >
              <ImageField source="src" title="title" />
            </ImageInput>
          </Grid>
        </Grid>
      </FormTab>
    </TabbedForm>
  </Create>
);
