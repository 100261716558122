import {
  List,
  Datagrid,
  TextField,
  EditButton,
  DeleteButton,
  Edit,
  TabbedForm,
  FormTab,
  TextInput,
  Create,
  required,
  ImageInput,
  ImageField,
  DateField,
  SimpleShowLayout,
  SelectInput,
  DeleteWithConfirmButton,
} from "react-admin";
import SimpleArray from "../../utils/getImages";
import {
  AlignmentButtons,
  ClearButtons,
  ColorButtons,
  FormatButtons,
  LevelSelect,
  LinkButtons,
  RichTextInput,
  RichTextInputToolbar,
} from "ra-input-rich-text";
import { Grid } from "@material-ui/core";

export const AboutusList = (props) => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="id" label="ID" />
      <TextField source="title_pl" label="Tytuł PL" />
      <TextField source="title_en" label="Tytuł EN" />
      <EditButton label="Edytuj" />
      <DeleteWithConfirmButton
        label="Usuń"
        confirmContent="Czy na pewno chcesz usunąć wpis O nas?"
        confirmTitle="Usuń wpis o nas!"
        color="error"
      />
    </Datagrid>
  </List>
);

export const AboutusEdit = (props) => (
  <Edit title="Edytuj info o nas" {...props}>
    <TabbedForm>
      <FormTab label="Język Polski">
        <Grid container spacing={2}>
          <Grid item xs="6">
            <TextInput source="title_pl" label="Tytuł po Polsku" />
          </Grid>
        </Grid>
        <RichTextInput
          source="description_pl"
          fullWidth
          label="Opis wpisu po Polsku"
          validate={required()}
          toolbar={
            <RichTextInputToolbar>
              <LevelSelect />
              <FormatButtons />
              <ColorButtons />
              <AlignmentButtons />
              <LinkButtons />
              <ClearButtons />
            </RichTextInputToolbar>
          }
        />
      </FormTab>
      <FormTab label="Język Angielski">
        <Grid container spacing={2}>
          <Grid item xs="6">
            <TextInput source="title_en" label="Tytuł po Angielsku" />
          </Grid>
        </Grid>
        <RichTextInput
          source="description_en"
          fullWidth
          label="Opis wpisu po Angielsku"
          validate={required()}
          toolbar={
            <RichTextInputToolbar>
              <LevelSelect />
              <FormatButtons />
              <ColorButtons />
              <AlignmentButtons />
              <LinkButtons />
              <ClearButtons />
            </RichTextInputToolbar>
          }
        />
      </FormTab>

      <FormTab label="Zdjęcia">
        <Grid container spacing={2}>
          <Grid item xs="12">
            <ImageInput
              source="gallery"
              label="Gallery"
              accept="image/png,image/jpg,image/jpeg"
              multiple
            >
              <ImageField source="src" title="title" />
            </ImageInput>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs="12">
            <SimpleShowLayout fullWidth>
              <SimpleArray source="images" />
            </SimpleShowLayout>
          </Grid>
        </Grid>
      </FormTab>
    </TabbedForm>
  </Edit>
);

export const AboutusCreate = (props) => (
  <Create title="Utwórz nową kategorię o nas" {...props}>
    <TabbedForm>
      <FormTab label="Język Polski">
        <Grid container spacing={2}>
          <Grid item xs="6">
            <TextInput source="title_pl" label="Tytuł PL" />
          </Grid>
        </Grid>
        <RichTextInput
          source="description_pl"
          fullWidth
          label="Treść wpisu po Polsku"
          validate={required()}
          toolbar={
            <RichTextInputToolbar>
              <LevelSelect />
              <FormatButtons />
              <ColorButtons />
              <AlignmentButtons />
              <LinkButtons />
              <ClearButtons />
            </RichTextInputToolbar>
          }
        />
      </FormTab>
      <FormTab label="Język Angielski">
        <Grid container spacing={2}>
          <Grid item xs="6">
            <TextInput source="title_en" label="Tytuł EN" />
          </Grid>
        </Grid>
        <RichTextInput
          source="description_en"
          fullWidth
          label="Treść wpisu po Angielsku"
          validate={required()}
          toolbar={
            <RichTextInputToolbar>
              <LevelSelect />
              <FormatButtons />
              <ColorButtons />
              <AlignmentButtons />
              <LinkButtons />
              <ClearButtons />
            </RichTextInputToolbar>
          }
        />
      </FormTab>
      <FormTab label="Zdjęcia">
        <Grid container spacing={2}>
          <Grid item xs="12">
            <ImageInput
              source="gallery"
              label="Gallery"
              accept="image/png,image/jpg,image/jpeg"
              multiple
            >
              <ImageField source="src" title="title" />
            </ImageInput>
          </Grid>
        </Grid>
      </FormTab>
    </TabbedForm>
  </Create>
);
